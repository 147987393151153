// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.numpad-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .numpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    max-width: 300px;
    margin: 20px auto;
  }
  
  button {
    padding: 15px;
    font-size: 18px;
    background-color: #4caf50;
    color: white;
    border: 1px solid #45a049;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .result {
    margin-top: 20px;
    font-size: 24px;
  }
  
  .apply-btn {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 18px;
    background-color: #008CBA;
    color: white;
    border: 1px solid #0077a3;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .apply-btn:hover {
    background-color: #0077a3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Numpad.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,cAAc;IACd,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".numpad-container {\n    text-align: center;\n    margin-top: 50px;\n  }\n  \n  .numpad {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 10px;\n    max-width: 300px;\n    margin: 20px auto;\n  }\n  \n  button {\n    padding: 15px;\n    font-size: 18px;\n    background-color: #4caf50;\n    color: white;\n    border: 1px solid #45a049;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #45a049;\n  }\n  \n  .result {\n    margin-top: 20px;\n    font-size: 24px;\n  }\n  \n  .apply-btn {\n    margin-top: 20px;\n    padding: 15px 30px;\n    font-size: 18px;\n    background-color: #008CBA;\n    color: white;\n    border: 1px solid #0077a3;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .apply-btn:hover {\n    background-color: #0077a3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
